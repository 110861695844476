import React from "react";
import { Link, graphql } from "gatsby";

import Head from "../components/head";
import Layout from "../components/layout";
import Section from "../components/section";
import Grid from "../components/grid";
// import Card from "../components/card";
// import CardService from "../components/card-link";
import CardService from "../components/image-title";
import CardProject from "../components/card-title-top";
import Lightbox from "../components/lightbox";
import ButtonTag from "../components/button-tag";
import SkillLevel from "../components/level";

export default ({ data }) => {
  const node = data.markdownRemark;
  const services = data.services ? data.services.edges : null;
  const projects = data.projects ? data.projects.edges : null;

  return (
    <div>
      <Head
        PageTitle={node.frontmatter.title + " - " + node.frontmatter.category}
        PageDescription={node.excerpt}
        PageSlug={node.fields.slug}
        PageImage={node.frontmatter.cover_image.childImageSharp.fluid.src}
      />

      <Layout>
        <Section Style="default">
          <div>
            <h3>
              <Link to={"/" + node.frontmatter.categoryId + "/"}>
                <small>{node.frontmatter.category}</small>
              </Link>{" "}
              <span
                className="uk-margin-small-right"
                data-uk-icon="chevron-right"
              />{" "}
              {node.frontmatter.title}
            </h3>
            <div data-uk-grid>
              <div className="uk-width-1-2@s">
                {/* <Lightbox Image={node.frontmatter.cover_image.publicURL} /> */}
                {/* {console.log(data.file.childImageSharp)} */}
                <Lightbox
                  ImageUrl={
                    node.frontmatter.cover_image.childImageSharp.original.src
                  }
                  ImageFluid={
                    node.frontmatter.cover_image.childImageSharp.fluid
                  }
                />
              </div>
              <div className="uk-width-1-2@s uk-flex-first@s">
                <div dangerouslySetInnerHTML={{ __html: node.html }} />
                <hr />
                <p>
                  <em>Niveau de maîtrise</em> :
                </p>
                <SkillLevel Level={node.frontmatter.level} />

                <hr />
                <p>
                  <em>Services</em> :
                </p>
                <div>
                  {services &&
                    services.map(({ node }) => {
                      return (
                        <ButtonTag key={node.id} To={node.fields.slug}>
                          {node.frontmatter.titleId}
                        </ButtonTag>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>

          <hr />

          <h4 id="projects" className="uk-text-center uk-margin-medium">
            Projets et réalisations associés
          </h4>
          <Grid Col="3">
            {projects &&
              projects.map(({ node }) => {
                return (
                  <div key={node.id}>
                    <CardProject
                      ImageFluid={
                        node.frontmatter.cover_image.childImageSharp.fluid
                      }
                      Label={node.frontmatter.date}
                      Title={node.frontmatter.title}
                      Subtitle={node.frontmatter.subtitle}
                      // Intro={node.excerpt}
                      // LinkUrl={node.fields.slug}
                      LinkUrl={"/projects/" + node.frontmatter.titleId}
                      LinkText="Découvrir"
                    />
                  </div>
                );
              })}
          </Grid>
        </Section>

        <Section Style="secondary">
          <h4
            id="services"
            className="uk-text-center uk-margin-medium uk-light"
          >
            Services et prestations associés
          </h4>
          <Grid Col="3">
            {services &&
              services.map(({ node }) => {
                return (
                  <div key={node.id} className="uk-light">
                    <CardService
                      ImageFluid={node.frontmatter.icon.childImageSharp.fluid}
                      Label=""
                      Title={node.frontmatter.title}
                      LinkUrl={node.fields.slug}
                    />
                  </div>
                );
              })}
          </Grid>
        </Section>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query($skill: String!, $services: [String]) {
    markdownRemark(frontmatter: { titleId: { eq: $skill } }) {
      html
      frontmatter {
        title
        titleId
        date(formatString: "MMMM YYYY")
        cover_image {
          childImageSharp {
            original {
              src
            }
            fluid(maxWidth: 848, maxHeight: 480) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        category
        categoryId
        services
        skills
        level
      }
      fields {
        slug
      }
      excerpt(pruneLength: 320)
    }
    services: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: ASC }
      filter: { frontmatter: { titleId: { in: $services } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            titleId
            icon {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "YYYY")
            category
            categoryId
            services
            skills
          }
          fields {
            slug
          }
          excerpt(pruneLength: 80)
        }
      }
    }
    projects: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { skills: { eq: $skill } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            titleId
            cover_image {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 225) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date(formatString: "YYYY")
            category
            categoryId
            services
            skills
          }
          fields {
            slug
          }
          excerpt(pruneLength: 80)
        }
      }
    }
  }
`;
